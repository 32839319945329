import React from 'react';
import { Link, graphql } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import tw from 'tailwind.macro';
import Fade from 'react-reveal/Fade';
import Scroll from 'react-scroll';
import { HotKeys } from 'react-hotkeys';

import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import { Tween, Controls, Timeline } from 'react-gsap';
import ImagePalette from 'react-image-palette';
import Color from 'color';
import ProximityFeedback from 'react-proximity-feedback';

import logger from 'utils/logger';
import { Menu } from 'components/Navigation';

import Layout from 'components/Layout';
import SEO from 'components/seo';

import {
  ProximityLogo
} from 'components/Elements';
import {
  BigIdea,
  Marker,
  PrettyTitle,
  SectionLabel
} from 'components/Text';
import {
  FeaturedEntry
} from 'components/Sections';

var Events = Scroll.Events;

const easing = 500;
const fullScreen = css`
  ${tw`top-0 left-0 w-screen h-screen m-0`};
`;
const currentSection = css`
  ${tw`relative`};
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  ${({ active, section }) => {
    return [
      active === section && tw``
    ];
  }}
`;

const Landing = styled.div`
  ${tw`overflow-hidden`};
  ${fullScreen}
`;
const LandingContent = styled.div`
  ${tw`h-full w-full`};
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
`;

const Welcome = styled.div`
  ${currentSection}
  ${tw`bg-white text-black sticky top-0`};
  ${fullScreen}
  ${({ active, section }) => {
    return [
      active !== section && `margin-top: -100vh`
    ];
  }}
`;
const Highlights = styled.div`
  ${tw`relative`};
  ${fullScreen}
`;
const FeaturedEntries = styled.div`
  ${tw`bg-white text-black text-center z-10 relative`};
  ${currentSection}
`;
const Entry = styled.div`
  ${tw`text-left absolute px-0 xxs:px-1 xs:px-3`};
  ${fullScreen};
  ${({
      index,
      currentEntry,
      alternativeColor,
      backgroundColor,
      contrastColor,
      color,
      colorScheme
    }) => {
    const maincolor = Color(backgroundColor);
    return [
      `opacity: ${index === currentEntry ? `1` : `0`} !important;`,
      maincolor.isDark() ? tw`text-white` : tw`text-black`,
      ` & hr {
          border: 0;
          height: 2px;
          background-image: linear-gradient(
            to right, 
            ${alternativeColor},
            ${alternativeColor},
            ${backgroundColor}
          );
        };
      `,
      index === currentEntry ? tw`pointer-events-auto` : tw`pointer-events-none`
    ];
  }}
`;
const TitleLink = styled.button`
  ${tw`opacity-90 bg-transparent p-0 m-0 text-left`};
  color: inherit;
  transition: all ${easing}ms;
  &:focus,
  &:hover {
    ${tw`opacity-100`};
  }
`;

const navItemHeight = 3;
const FeaturedEntriesNav = styled.nav`
  ${tw`absolute left-0 m-0 pb-2rem`};
  top: 50vh;
  transform: translate3d(0,-50%,0);
  ${({ length }) => {
    return [
      `height: ${(navItemHeight * length * 2) - 0.25}rem;`
    ];
  }}
`;

const NavItem = styled.button`
  ${tw`block m-0 p-0 absolute opacity-50 bg-transparent w-3 md:w-6.5 xl:w-12 cursor-pointer`};
  height: ${navItemHeight}rem;
  &:before {
    ${tw`absolute left-0 m-0 bg-black opacity-40 w-1.25 md:w-1.5 xl:w-3`};
    content: '';
    position: absolute;
    top: 50%;
    height: 2px;
    margin: -1px 0 0 0;
    transform-origin: 0% 50%;
    transition: all 0.5s;
    transition-timing-function: cubic-bezier(.1,.55,.4,1);
  }
  &:focus,
  &:hover {
    ${tw`opacity-90`};
    &::before,
    &::before {
      ${tw`opacity-80 md:w-2 xl:w-4`};
    }
  }
  ${({
      index,
      currentEntry,
      length,
      alternativeColor,
      backgroundColor,
      contrastColor,
      color,
      colorScheme
    }) => {
    const positionIdle = index < currentEntry
      ? (-navItemHeight * (length - index)) + navItemHeight
      : navItemHeight * (index + length);
    const easingTiming = index <= currentEntry
      ? (length + index) / length
      : (length - index) / (length / 2);

    const maincolor = Color(backgroundColor);

    const contrastHEX = colorScheme === `light` ? `#fff` : `#000`;

    return [
      `top: ${index === currentEntry ? 0 : positionIdle}rem;`,
      `transition: all ${(easing * easingTiming) + 200}ms;`,
      `transition-timing-function: cubic-bezier(.1,.55,.4,1);`,
      `color: ${contrastHEX};`,
      `&::before {
        background-color: ${index === currentEntry ? contrastHEX : contrastHEX};
        transition: all ${(easing * easingTiming) + 200}ms;
      }`,
      index === currentEntry && `
        &::before,
        &:focus::before,
        &:hover::before {
          opacity: .5;
          width: 60%;
        }
      `
    ];
  }}
`;
const NavLabel = styled.label`
  ${tw`absolute block overflow-hidden font-regular text-small mb-0 right-0 md:pr-3 cursor-pointer`};
  top: .5rem;
  white-space: nowrap;
  transition: all ${easing}ms,
  transition-timing-function: cubic-bezier(.1,.55,.4,1);
  & span {
    display: block;
    transition: padding ${easing}ms;
    transition-timing-function: cubic-bezier(0.2,1,0.3,1);
  }
  ${({ index, currentEntry }) => {
    return [
      index === currentEntry ? tw`md:pr-0 xl:pr-1.25` : tw`md:pr-2.5 xl:pr-5`
    ];
  }}
`;

const Background = styled.div`
  ${tw`absolute w-full h-full left-0 top-0 overflow-hidden`};
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);

  &::after{
    ${tw`absolute w-0 h-full top-0`};
    transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
    content: ' ';
  }
  ${({
    distance, bgImage, alternativeColor, backgroundColor, color, index, currentEntry
  }) => {
    return [
      `opacity: ${index === currentEntry ? 1 : 0};`,
      `color: ${color || `transparent`} !important;`,
      `background-color: ${backgroundColor || `transparent`};`,
      `&::after{
        width: ${index === currentEntry ? `100` : `100`}%;
        background-color: ${backgroundColor || `transparent`};
        ${bgImage && `background-image: url('${bgImage}');`}
        ${bgImage ? `background-size: cover;` : ``}
        ${bgImage ? `background-repeat: no-repeat;` : ``}
        ${bgImage ? `background-position: ${index === currentEntry ? `0` : `-5`}rem center;` : ``}
      };`
    ];
  }};
`;

const pulse = keyframes`
  0% {
    ${tw`h-px`};
  }
  50% {
    ${tw`h-2.5`};
    top: 2.5rem;
  }
  80% {
    top: 5rem;
  }
`;

const ScrollToContent = styled.div`
  ${tw`absolute bottom-0 uppercase cursor-pointer font-sm opacity-60 pb-3 xl:w-1/3 text-center`};
  left: 50%; transform: translateX(-50%);
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  &:before,
  &:after{
    ${tw`absolute bg-white block opacity-50`};
    left: 50%; transform: translateX(-50%);
    transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
    content: ' ';
    width: 2px;
  }
  &:before{
    ${tw`bottom-0 h-2.5`};
  }
  &:after{
    ${tw`opacity-0`};
    top: 2.5rem;
  }
  &:focus,
  &:hover {
    ${tw`opacity-80 pb-3`};
    &:before,
    &:after{
      ${tw`opacity-50`};
    }
    &:after{
      animation: ${pulse} ${easing * 3}ms infinite normal;
    }
  }
`;

const ColorPalette = ({ image, children }) => {
  return (
    <ImagePalette image={image} crossOrigin>
      {({ backgroundColor, color, alternativeColor }) => {
        const maincolor = Color(backgroundColor);
        const childrenProps = {
          contrastColor: maincolor.isLight() ? `light` : `dark`,
          backgroundColor,
          color,
          alternativeColor
        };
        return (
          React.cloneElement(children, childrenProps)
        );
      }}
    </ImagePalette>
  );
};

// const Ref = styled.div`
//   ${tw`
//     absolute
//     block
//     py-1
//     px-1
//     p-l
//     h-1
//     invisible
//   `};
//   width: 75vw;
//   top: 50%;
//   transform: translateY(-50%);
// `;

class Index extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      section: `welcome`,
      scrollLock: true,
      colorScheme: `dark`,
      currentEntry: null
    };
    this.handleToggleSection = this.handleToggleSection.bind(this);
    this.handleMouseScrollTo = this.handleMouseScrollTo.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleFeaturedEntriesNav (index, contrastColor) {
    logger.verbose(`handleFeaturedEntriesNav (index, props)`, index, contrastColor);
    this.setState({
      currentEntry: index,
      colorScheme: contrastColor
    });
  }

  handleToggleSection (from, to, direction) {
    const { section } = this.state;
    this.setState({
      section
    });
  }

  handleScroll (e, section, entries, current) {
    const length = entries.length - 1;
    if (e.deltaY > 0 && section === `welcome`) {
      this.handleMouseScrollTo(`features`, 0);
      this.handleFeaturedEntriesNav(0, entries[0].mood);
    } else if (e.deltaY > 0 && section === `features` && current < length) {
      const goTo = current + 1;
      this.handleFeaturedEntriesNav(goTo, entries[goTo].mood);
    } else if (e.deltaY < 0 && section === `features` && current > 0) {
      const goTo = current - 1;
      this.handleFeaturedEntriesNav(goTo, entries[goTo].mood);
    } else if (e.deltaY < 0 && section === `features` && current === 0) {
      this.handleMouseScrollTo(`welcome`);
    }
  }

  handleMouseScrollTo (target, slide = 0) {
    logger.verbose(`scrollTo (${target})`);
    const currentEntry = target === `welcome` ? null : slide;
    this.setState({
      section: target,
      currentEntry
    });
  }

  render () {
    logger.template(__filename, '>', 'render()');
    const { props } = this;
    const { data } = props;
    const { state } = this;
    logger.gql('data:', data);
    logger.verbose('state:', state);
    const siteTitle = data.site.siteMetadata.title;

    const entries = [
      {
        id: `1`,
        title: `Entry 1`,
        prettyTitle: <PrettyTitle>First title <mark>key</mark> KEY <b>contrast</b></PrettyTitle>,
        type: `insights`,
        text: `Energid confounding recitement madrigalian sequacity rancidification tetrasubstituted roughhouser protractedly.`,
        featuredImage: `/testing/nike.jpg`,
        // featuredImage: `https://images.ctfassets.net/fiz3jwws2um7/3z0pE2k7v9j1lYa1aorhSN/ed9281298a90df75772061ee29dfbda1/Hugeinc_Predictions_Hugeinc_1920x1080_d.jpg?w=1600`,
        mood: `light`
      },
      {
        id: `2`,
        title: `Entry 2`,
        prettyTitle: <PrettyTitle><i>Case study</i> with <strong>mojo</strong></PrettyTitle>,
        type: `case study`,
        text: `Barraclade darner Benjamite zuccarino caballer underproficient varicoblepharon cryptogam sombrero perispheric hobbledehoyish.`,
        featuredImage: `/testing/maybelline_hero-1x.jpg`,
        // featuredImage: `https://media.giphy.com/media/BHNfhgU63qrks/giphy.gif`,
        mood: `light`
      },
      {
        id: `3`,
        title: `Entry 3`,
        prettyTitle: <PrettyTitle>The not so <mark>ART</mark> <i>of</i> <strong>Branding.</strong></PrettyTitle>,
        type: `decks`,
        text: `Osiridean inweave tellinoid Banderma naming methide Placaean cornute karaya mimeo oftens bibble.`,
        featuredImage: `/testing/faraday_hero-1x.webp`,
        // featuredImage: `https://images.unsplash.com/photo-1517646331032-9e8563c520a1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80`,
        mood: `light`
      },
      {
        id: `4`,
        title: `Entry 4`,
        prettyTitle: <PrettyTitle>Another <b>cool</b> case study.</PrettyTitle>,
        type: `case study`,
        text: `Cause hideland overtedious lyrately cloakedly paronomasial vizardlike beladle sericea Gaetuli imperance overdischarge.`,
        // featuredImage: `/testing/Hyundai_1.jpg`,
        featuredImage: `/testing/primrose_hero-2x.jpg`,
        mood: `dark`
      },
      {
        id: `5`,
        title: `Entry 5`,
        prettyTitle: <PrettyTitle><strong>PR</strong> ref perhaphs?</PrettyTitle>,
        type: `media`,
        text: `Paronomasial vizardlike beladle sericea Gaetuli hideland overtedious lyrately cloakedly overdischarge.`,
        // featuredImage: `https://images.unsplash.com/photo-1494253109108-2e30c049369b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80`,
        featuredImage: `/testing/nike.jpg`,
        video: `https://foundersfund.com/wp-content/uploads/2016/04/airbnb.mp4`,
        mood: `light`
      }
    ];

    const keyMap = {
      MOVE_UP: [`up`, `pageup`, `left`],
      MOVE_DOWN: [`down`, `pagedown`, `right`],
      0: [`0`, `home`],
      1: [`1`, `end`],
      2: [`2`],
      3: [`3`],
      4: [`4`],
      5: [`5`]
    };

    const handlers = {
      0: e => this.handleMouseScrollTo(`welcome`),
      1: state.section === `features`
        ? e => this.handleFeaturedEntriesNav(0, entries[0].mood)
        : e => this.handleMouseScrollTo(`features`, 0),
      2: state.section === `features`
        ? e => this.handleFeaturedEntriesNav(1, entries[1].mood)
        : e => this.handleMouseScrollTo(`features`, 1),
      3: state.section === `features`
        ? e => this.handleFeaturedEntriesNav(2, entries[2].mood)
        : e => this.handleMouseScrollTo(`features`, 2),
      4: state.section === `features`
        ? e => this.handleFeaturedEntriesNav(3, entries[3].mood)
        : e => this.handleMouseScrollTo(`features`, 3),
      5: state.section === `features`
        ? e => this.handleFeaturedEntriesNav(4, entries[4].mood)
        : e => this.handleMouseScrollTo(`features`, 4),
      MOVE_UP: state.section === `features` && state.currentEntry > 0
        ? e => this.handleFeaturedEntriesNav(
          state.currentEntry - 1, entries[state.currentEntry - 1].mood
        )
        : e => this.handleMouseScrollTo(`welcome`),
      MOVE_DOWN: state.section === `welcome`
        ? e => this.handleMouseScrollTo(`features`)
        : e => this.handleFeaturedEntriesNav(
          (state.currentEntry < (entries.length - 1)
            ? state.currentEntry + 1
            : state.currentEntry
          ),
          (state.currentEntry < (entries.length - 1)
            ? (entries[state.currentEntry + 1]).mood
            : (entries[state.currentEntry].mood)
          )
        )
    };

    logger.templateEnd();
    return (
      <Layout location={props.sectionLanding} title={siteTitle}>
        <SEO
          title={`THINKLO | Innovation, Design & Strategy firm`}
          titleSufix={false}
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Menu colorScheme={state.colorScheme} showLogo={state.section === `features` && true} />
        <ScrollLock isActive={state.scrollLock} />
        <HotKeys keyMap={keyMap} handlers={handlers}>
          <Welcome section={`welcome`} active={state.section} onWheel={e => this.handleScroll(e, `welcome`, entries)}>
            <ProximityLogo />
            <ScrollToContent
              onClick={e => this.handleMouseScrollTo(`features`)}
              role={`link`}
            >
              Highlights
            </ScrollToContent>
          </Welcome>
          <Highlights name={`features`}>
            { entries && entries.map((entry, i) => {
              const bgProps = {
                bgImage: entry.featuredImage
              };
              const navProps = {
                index: i,
                currentEntry: state.currentEntry,
                length: entries.length
              };
              return (
                <ColorPalette key={`ColorPaletteBackground-${entry.id}`} image={entry.featuredImage}>
                  <Background key={`Background-${entry.id}`} {...bgProps} {...navProps}>
                    {entry.video && (
                      <video
                        src={entry.video}
                        style={{
                          position: `absolute`,
                          minHeight: `100%`,
                          minWidth: `100%`,
                          width: `auto`,
                          height: `auto`,
                          top: `50%`,
                          left: `50%`,
                          zIndex: `1`,
                          transform: `translateY(-50%) translateX(-50%)`
                        }}
                        preload
                        muted
                        loop
                        autoPlay
                      >
                        <source src={entry.video} type="video/mp4" />
                      </video>
                    )}
                  </Background>
                </ColorPalette>
              );
            })}
            <FeaturedEntries section={`features`} active={state.section}>
              { entries && entries.map((entry, i) => {
                const n = `0${i + 1}`;
                const navProps = {
                  index: i,
                  currentEntry: state.currentEntry,
                  length: entries.length,
                  colorScheme: state.colorScheme,
                  globalAccentColor: state.globalAccentColor
                };
                const animation = {
                  duration: 1000
                };
                const onWheelHandler = e => this.handleScroll(e, `features`, entries, i);
                return (
                  <ColorPalette key={`ColorPaletteEntry-${entry.id}`} image={entry.featuredImage}>
                    <Entry key={`Entry-${entry.id}`} aria-label={`Item ${n}`} {...navProps} onWheel={onWheelHandler}>
                      <FeaturedEntry>
                        <Fade
                          left
                          when={state.currentEntry === i}
                          duration={animation.duration - (2 * 200)}
                          distance={`10%`}
                        >
                          <SectionLabel label={entry.type} />
                        </Fade>
                        <Fade
                          top
                          when={state.currentEntry === i}
                          duration={animation.duration - (2 * 200)}
                          distance={`10%`}
                          delay={(animation.duration / 10) + (2 * 50)}
                        >
                          {entry.prettyTitle}
                        </Fade>
                        <Fade
                          when={state.currentEntry === i}
                          duration={animation.duration * 1.5}
                          distance={`20%`}
                          delay={(animation.duration / 10) + (3 * 50)}
                        >
                          <hr />
                        </Fade>
                        <Fade
                          bottom
                          when={state.currentEntry === i}
                          duration={animation.duration - (2 * 200)}
                          distance={`20%`}
                          delay={(animation.duration / 10) + (2 * 50)}
                        >
                          <p>{entry.text}</p>
                        </Fade>
                      </FeaturedEntry>
                    </Entry>
                  </ColorPalette>
                );
              })}
              <FeaturedEntriesNav length={entries.length}>
                { entries && entries.map((entry, i) => {
                  const n = `0${i + 1}`;
                  const navProps = {
                    index: i,
                    currentEntry: state.currentEntry,
                    length: entries.length,
                    mood: entry.mood,
                    colorScheme: state.colorScheme
                  };
                  return (
                    <ColorPalette key={`ColorPaletteNavItem-${entry.id}`} image={entry.featuredImage}>
                      <NavItem key={`NavItem-${entry.id}`} aria-label={`Item ${n}`} {...navProps} onClick={e => this.handleFeaturedEntriesNav(i, entry.mood)}>
                        <NavLabel {...navProps}>
                          <span>
                            {n}
                          </span>
                        </NavLabel>
                      </NavItem>
                    </ColorPalette>
                  );
                })}
              </FeaturedEntriesNav>
            </FeaturedEntries>
          </Highlights>
        </HotKeys>
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
